import React from 'react'

import { useLocation, Redirect } from '@reach/router'

import { AuthConsumer } from '../providers/AuthProvider'

import JobScreen from '../components/JobScreen'
import Layout from '../components/Layout'
import SEO from '../components/SEO'

import { getSelectedId } from '../utils/helpers'

const JobPage = () => {
  const location = useLocation()
  const jobId = getSelectedId(location.search) || ''

  return (
    <Layout menuSelectedKeys={['jobs']}>
      <SEO title="Job Applications" />

      <AuthConsumer>
        {({ isLoading, data }) => {
          if (isLoading) {
            return <div>Loading...</div>
          }

          if (!data) {
            return <div>Not logged in</div>
          }

          return (
            <div className="content">
              {jobId ? (
                <JobScreen authData={data} jobId={jobId} />
              ) : (
                <Redirect to="/jobs" noThrow />
              )}
            </div>
          )
        }}
      </AuthConsumer>
    </Layout>
  )
}

export default JobPage
